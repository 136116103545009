export const heroData = {
    title: 'Simbios Digital Agency',
    text: 'Мы — агентство полного цикла. Наши услуги включают маркетинговую стратегию, брендинг, производство рекламных материалов, разработку сайта, поддержку и эффективное привлечение клиентов',
    buttons: [{
            text: 'Отправить заявку',
            class: 'simple',
            type: 'external',
            url: 'https://t.me/simbios_agency'
        },
        {
            text: 'Посмотреть кейсы',
            class: 'white',
            type: 'internal',
            url: '/cases/'
        }
    ]
}