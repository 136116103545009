import homeAnimation1 from '../../images/illustrations/home-1/data.json'
import homeAnimation2 from '../../images/illustrations/home-2/data.json'
import homeAnimation3 from '../../images/illustrations/home-3/data.json'
import homeAnimation4 from '../../images/illustrations/home-4/data.json'

export const advantages = [
    {
        id: '1',
        title: 'Разработка сайтов под ключ',
        text: 'Делаем веб-проекты для организации продаж и обслуживания клиентов: корпоративные сайты',
        image: 'advantages/advantage-1.svg',
        animation: homeAnimation1
    },{
        id: '2',
        title: 'SEO: оптимизация',
        text: 'Управляем контекстной рекламой в Яндекс.Директе, Google Ads, на прайс-площадках.',
        image: 'advantages/advantage-2.svg',
        animation: homeAnimation2
    },{
        id: '3',
        title: 'Фирменный стиль',
        text: 'Разрабатываем систему визуальной идентификации, которая будет долго работать и успешно решать новые дизайн-задачи. Внедряем и фиксируем систему в брендбуке и шаблонах.',
        image: 'advantages/advantage-3.svg',
        animation: homeAnimation3
    },{
        id: '4',
        title: 'UI/UX design',
        text: 'Создаём осмысленный и эстетичный дизайн интерфейсов, который помогает компаниям решать бизнес-задачи.',
        image: 'advantages/advantage-4.svg',
        animation: homeAnimation4
    },
]
