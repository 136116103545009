export const content = {
    backersSection: {
        title: 'Backers',
        subtitle: 'Celestia is backed by the world\'s top crypto funds and ecosystem builders.'
    },
}

export const FooterBoxes = [
    {
        title: 'Связаться с нами',
        text: 'Напишите нам и наши менеджеры обязательно с Вами свяжуться',
        button: {
            text: 'Контакты',
            href: '/contacts',
            id: 'operator',
            type: 'simple'
        }
    },
    {
        title: 'Присоединяйтесь в нашу группу Telegram',
        text: 'Все последние новости и события нашего агентство!',
        button: {
            text: 'Перейти',
            href: 'https://t.me/simbios_agency',
            id: 'career',
            type: 'simple'
        }
    }
]
